.line-float-btn{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 30px;
    z-index: 100;
  }
  .icon-style{
    background-color: #00AC00;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 66px;
    width: 66px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #00AC00;
    border: 2px solid #00AC00;
  }
  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    50%{
      transform: translateY(20px);
    }
    100%{
      transform: translateY(0px);
    }
  }